<template>
  <div>
    <!-- S:Bread crumbs -->
    <v-row>
      <v-col sm="12" md="12" lg="12" class="pa-10 pb-2">
        <v-card elevation="1" class="containerbox">
          <breadcrumbComp class="hidden-sm-and-down" :mainPage="mainPage" :subPage="subPage" :backSlash="backSlash" :pageUrl="pageUrl"
            :showAdd="showAdd" @addFav="addFav()" :refresh="refresh" :resetFunction="resetFunction" />
          <!--  Bread Crumb for mobile -->
          <breadcrumbComp class="hidden-md-and-up" :subPage="subPage" :pageUrl="pageUrl" :showAdd="showAdd" @addFav="addFav()" :refresh="refresh"
            :resetFunction="resetFunction" />
          <!-- E:Breadcrumbs -->
          <!-- Search Card -->
          <v-row>
            <v-col cols="12" sm="12" md="12" lg="12" class="pa-4">
              <v-card outlined color="transparent" class="pa-0 ma-0 containerbox pb-5">
                <v-card-text class="pb-0 pt-0">
                  <v-form ref="businessForm" v-model="isFormValid" class="pa-2 pr-5" lazy-validation>
                    <v-row class="pt-5">
                      <!-- Project -->
                      <v-col cols="12" sm="12" md="12" lg="8" class="px-5 py-0">
                        <v-autocomplete color="primarytext" attach outlined v-model="selectedProject" :items="projectData" item-text="Project"
                          item-value="ProjKey" label="Project" dense persistent-placeholder @change="getBusinessList()">
                        </v-autocomplete>
                      </v-col>
                      <!-- For business Code -->
                      <v-col cols="12" sm="12" md="12" lg="2" class="px-5 py-0">
                        <v-switch class="ma-2 d-inline-block" v-model="active" :label="active ? 'Active' : 'Inactive'" color="primarytext"
                          hide-details inset @change="getBusinessList()"></v-switch>
                      </v-col>
                      <v-col cols="12" sm="12" md="12" lg="2" class="px-5 py-0">
                        <v-btn color="secondary" outlined dense class="ma-2 btn-style" elevation="1" @click="onSearchCancel()">
                          <v-icon dark left> mdi-magnify-close </v-icon>Clear Search
                        </v-btn>
                      </v-col>
                    </v-row>
                  </v-form>
                </v-card-text>
              </v-card>
            </v-col>
          </v-row>
        </v-card>
      </v-col>
    </v-row>
    <!-- Table -->
    <v-row>
      <v-col cols="12" sm="12" md="12" lg="12" class="pa-10 pt-5">
        <v-card outlined class="containerbox">
          <v-card-text class="pb-0 pt-0">
            <v-row>
              <v-col cols="12" sm="6" md="6" lg="6" class="px-5 pt-10 pl-8">
                <span color="primarytext">
                  No.of Records: {{ totalRecords }}
                </span>
              </v-col>
              <v-col cols="12" sm="6" md="6" lg="3" class="px-2 ma-0 pt-7">
                <v-text-field color="primarytext" rounded class="searchbar" v-model="searchBusinessList" append-icon="mdi-magnify" label="Search"
                  outlined clearable dense @input="searchBusinessList = searchBusinessList?.toUpperCase()" persistent-placeholder hide-details>
                </v-text-field>
              </v-col>
              <v-col cols="12" sm="6" md="6" lg="3" class="px-2 ma-0 pt-6 pr-8">
                <!-- Button to add user -->
                <v-btn class="my-2 mx-0 btn-style float-right px-2 success" elevation="1" outlined color="cWhite" @click="newBusinessClicked()"
                  v-if="actionSetter('AddBusiness')">
                  <v-icon dark left> mdi-plus </v-icon>New Business
                </v-btn>
                <!-- Company add Comp -->
                <export-excel class="btn btn-default" :data="businessList" :fields="json_fields" worksheet="Sheet1" :name="excelName"
                  v-if="actionSetter('ExportBusiness') && !exportDisable">
                  <!-- Button to add user -->
                  <v-btn class="ma-2 btn-style float-right" elevation="1" outlined color="secondary" @click="fileName()">
                    <v-icon dark left> mdi-file-export </v-icon>Export
                  </v-btn>
                </export-excel>
              </v-col>
            </v-row>
            <v-row>
              <v-col sm="12" md="12" lg="12" class="pa-0 pl-8 pr-8 pb-5">
                <v-data-table :headers="headers" :items="businessList" item-key="business_code" :search="searchBusinessList">
                  <template v-slot:item.actions="{ item }">
                    <v-icon small class="mr-2" @click="businessDetails(item)">
                      mdi-eye
                    </v-icon>
                  </template>

                  <template v-slot:item.business="{ item }">
                    <a @click="businessDetails(item)" v-bind:class="($vuetify.theme.dark) ? 'anchorDark' : 'anchorlight'">
                      {{ item.business }}
                    </a>
                  </template>
                </v-data-table>
              </v-col>
            </v-row>
            <businessComp v-if="compCalled" :name="name" @hide="close()" @businessChange="updatedBusiness()" :bu_id="bu_id" :compCalled="compCalled"
              :projectData="projectData" :actionList="actionList" />
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import Vue from 'vue';
import excel from 'vue-excel-export';
import breadcrumbComp from '../common/breadcrumb-comp.vue';
import EncryptUtility from '../utility/encrypt-utility';
//import Utility from '../../shared/utility';
import AdminService from "./js/admin-service.js"
import businessComp from './app-business-comp.vue';
import AddFavourite from "../utility/addFav";
import commonAPIService from "../common/js/common-api-service";
Vue.use(excel);
export default {
  data: () => ({
    name: 'newBusiness',
    favouriteList: [],
    compCalled: false,
    backSlash: true,
    mainPage: '',
    subPage: "",
    pageUrl: "",
    active: true,
    showAdd: false,
    exportDisable: true,
    isFormValid: false,
    bu_id: '',
    selectedProject: '',
    userId: EncryptUtility.localStorageDecrypt('userID'),
    businessList: [],
    totalRecords: 0,
    json_fields: {
      BusinessName: 'business',
      BusinessCode: 'bu_code',
      Project: 'project',
    },
    json_meta: [
      [
        {
          key: 'charset',
          value: 'utf-8',
        },
      ],
    ],
    headers: [
      { text: 'Business Name', align: 'start', value: 'business', class: 'primary customwhite--text', },
      { text: 'Business Code', value: 'bu_code', class: 'primary customwhite--text', },
      { text: 'Project', value: 'project', class: 'primary customwhite--text', },
      { text: 'Actions', value: 'actions', class: 'primary customwhite--text', sortable: false, },
    ],
    excelName: '',
    projectData: [],
    actionList: [],
    refresh: true,
    route: '',
    searchBusinessList: "",
  }),
  async created() {
    this.route = this.$route.path.replace('/', '');
    let data = await AddFavourite.getPageAction(this.userId, this.route)
    this.actionList = data.actionList
    this.mainPage = data.mainPage
    this.favouriteList = data.favouriteList
    this.pageUrl = data.pageUrl
    this.subPage = data.subPage
    this.showAdd = data.showAdd
  },
  mounted() {
    this.getProjectList();
  },
  methods: {
    //Reset function
    resetFunction() {
      this.selectedProject = "";
      this.projectData = [];
      this.active = true;
      this.totalRecords = 0;
      this.businessList = [];
      this.$refs.businessForm.resetValidation();
      this.getProjectList();
    },
    //To close the file
    close() {
      this.name = '';
      this.compCalled = false;
      if (this.totalRecords > 0)
        this.getBusinessList();
    },
    //When new business is added or existing business updated
    updatedBusiness() {
      this.name = '';
      this.compCalled = false;
      this.getBusinessList();
    },
    //Favorite icon
    addFav() {
      this.showAdd = !this.showAdd
      AddFavourite.add_remove_Favourite(this.showAdd, this.userId, this.route)
    },
    //Get Project List
    async getProjectList() {
      this.projectData = [];
      let data = await commonAPIService.getProjectForBusiness("get", this.userId);
      if (data.message !== "NA") {
        this.projectData = data.ProjectInfo;
      }
    },
    //Get Businsess List
    async getBusinessList() {
      let searchObject = {
        BusinessCode: '',
        BusinessName: '',
        Proj_key: this.selectedProject,
        strActive: this.active ? '1' : '0',
        UserId: parseInt(this.userId),
      };
      this.totalRecords = 0;
      let businessData = await AdminService.getBusinessList("post", searchObject, false)
      this.businessList = businessData;
      this.totalRecords = businessData.length;
      this.exportDisable = false;

    },
    //Business Details
    businessDetails(item) {
      //do not delete it
      this.bu_id = item.bu_id;
      this.compCalled = true;
      this.name = 'editBusiness';
    },
    //File Name
    fileName() {
      this.excelName =
        'Business_List_' +
        new Date()
          .toISOString()
          .replace('T', '_')
          .replace('Z', '')
          .replace('.', '')
          .replaceAll('-', '_') +
        '.xls';
    },
    //Search Cancel Button
    onSearchCancel() {
      this.selectedProject = '';
      this.active = true;
      this.businessList = [];
      this.totalRecords = 0;
      this.exportDisable = true;
      this.getProjectList();
    },
    //New Business button clicked
    newBusinessClicked() {
      this.compCalled = true;
      this.name = 'newBusiness';
    },
    //To set the action
    actionSetter(action) {
      if (this.actionList !== null && this.actionList.length > 0) {
        let filter = this.actionList.filter(
          (entry) => entry.SystemName === action,
        );
        return filter !== undefined && filter !== null && filter.length == 1
          ? true
          : false;
      } else return false;
    },
  },
  components: {
    breadcrumbComp,
    businessComp,
  },
};
</script>

<style scoped>
.active {
  background-color: #2ed8b6;
  border-radius: 5px;
  padding: 5px;
  color: white;
}

.inactive {
  background-color: #ff5370;
  border-radius: 5px;
  padding: 5px;
  color: white;
}

.add-menu {
  overflow-y: hidden !important;
  overflow-x: hidden !important;
}
</style>